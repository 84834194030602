import React,{useEffect, useState} from "react";
import './post.css'


export function Post (){
    //const [post, setPost] = useState([]);

    /*useEffect(()=>{
        const identificator = window.location.pathname;
        const regex = /(\d+)/g;
        const id = identificator.match(regex);
        fetch('https://control.dos17.digital/wp-json/wp/v2/posts/'+id)
            .then(response => response.json())
            .then(response => setPost([response]))
    },[])*/

    const [entrada, setEntrada] = useState([]);

    useEffect(()=>{
        document.body.scrollTop = 0;
        const identificator = window.location.pathname;
        const id = identificator.slice(6);
        fetch('https://control.dos17.digital/wp-json/wp/v2/posts/'+id)
            .then(post => post.json())
            .then(post => setEntrada([post]))
    },[])

    return  <>
        <article className="container mx-auto">
            {
                entrada.map((art, index)=>{
                    return <div key={index} className="blog-post">
                        <img src={art.featured_media_src_url} alt="" className="blog-post-img"/>
                        <h1 className="blog-post-title">{art.title.rendered}</h1>
                        <div className="width-post" dangerouslySetInnerHTML={{__html: art.content.rendered}}>
                        </div>
                    </div>
                })
            }
        </article>
    </>
}
